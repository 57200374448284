import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faMoneyBillWave,
  faHandshake,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

interface FeatureProps {
  icon: string;
  title: string;
  description: string;
}

const iconMap: { [id: string]: IconDefinition } = {
  faShieldAlt: faShieldAlt,
  faMoneyBillWave: faMoneyBillWave,
  faHandshake: faHandshake,
};

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => {
  return (
    <div className="text-center" style={{ marginBottom: "40px" }}>
      <FontAwesomeIcon icon={iconMap[icon]} style={{ color: "#50C878", fontSize: "30px" }} />
      <h3>{title}</h3>
      <p style={{ fontSize: "20px", color: "darkGrey" }}>{description}</p>
    </div>
  );
};

export default Feature;
